var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mx-1",attrs:{"rounded":"","small":"","color":"success"},on:{"click":function($event){return _vm.cambiarRuta({ nameRuta: 'nuevoProveedor' })}}},[_vm._v(" NUEVO ")]),_c('v-btn',{staticClass:"mx-1",attrs:{"rounded":"","small":"","color":"secondary"},on:{"click":function($event){_vm.$store.state.entities.filtrarProveedor =
            !_vm.$store.state.entities.filtrarProveedor}}},[_vm._v(" FILTRAR ")]),_c('v-btn',{staticClass:"mx-1",attrs:{"rounded":"","small":"","color":"warning"},on:{"click":function($event){return _vm.exportProveedor()}}},[_vm._v("EXPORTAR")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-spacer'),_c('v-text-field',{attrs:{"name":"Buscar","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.$store.state.entities.lstProveedor,"item-key":"id","loading":"true","search":_vm.search},scopedSlots:_vm._u([{key:"item.codigo",fn:function(ref){
            var item = ref.item;
return [_vm._v(" PROV ")]}},{key:"item.documento",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.acronym_document)+"-"+_vm._s(item.document)+" ")]}},{key:"item.ubigeo",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.pais)+"-"+_vm._s(item.city)+" ")]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-btn-toggle',[_c('v-btn',{attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.obtenerTelContactoProveedor(item.id)}}},[_c('v-icon',{attrs:{"color":"blue-grey darken-3"}},[_vm._v("mdi-phone")])],1),(item.status == 1)?_c('v-btn',{attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'editarProveedor',
                  params: { id: item.id },
                })}}},[_c('v-icon',{attrs:{"color":"#E65100"}},[_vm._v("mdi-pencil")])],1):_vm._e(),_c('v-btn',{attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'verProveedor',
                  params: { id: item.id },
                })}}},[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("mdi-eye")])],1),(item.status == 1)?_c('v-btn',{attrs:{"x-small":"","icon":""}},[_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.eliminar(item)}}},[_vm._v("mdi-delete")])],1):_vm._e()],1)]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"50%","transition":"dialog-transition","scrollable":""},model:{value:(_vm.dialogTelCont),callback:function ($$v) {_vm.dialogTelCont=$$v},expression:"dialogTelCont"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Teléfonos y Contactos ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-subheader',[_c('b',[_vm._v("Teléfonos:")]),_vm._v(" "),_c('br')])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-5",attrs:{"headers":_vm.headersTel,"items":_vm.$store.state.entities.lstTelefono,"item-key":"id"},scopedSlots:_vm._u([{key:"item.tipotelefono",fn:function(ref){
                var item = ref.item;
return [_c('v-autocomplete',{attrs:{"items":_vm.$store.state.masterusuarios.lstTipoTelefono,"item-text":"descripcion","item-value":"id","label":"Tipo de Teléfono","error-messages":item.errorTipoTelf,"readonly":""},model:{value:(item.id_tipotelefono),callback:function ($$v) {_vm.$set(item, "id_tipotelefono", $$v)},expression:"item.id_tipotelefono"}})]}},{key:"item.nro_telefono",fn:function(ref){
                var item = ref.item;
return [_c('v-text-field',{attrs:{"prefix":"+","error-messages":item.errorTelefono},model:{value:(item.telefono),callback:function ($$v) {_vm.$set(item, "telefono", $$v)},expression:"item.telefono"}})]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.eliminarTelefono(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-subheader',[_c('b',[_vm._v("Contactos")])])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-5",attrs:{"headers":_vm.headersContact,"items":_vm.$store.state.entities.lstContactos,"item-key":"index"},scopedSlots:_vm._u([{key:"item.nombre",fn:function(ref){
                var item = ref.item;
return [_c('v-text-field',{attrs:{"readonly":"","error-messages":item.errornombre},model:{value:(item.nombre),callback:function ($$v) {_vm.$set(item, "nombre", $$v)},expression:"item.nombre"}})]}},{key:"item.tipotelefono",fn:function(ref){
                var item = ref.item;
return [_c('v-autocomplete',{attrs:{"items":_vm.$store.state.masterusuarios.lstTipoTelefono,"item-text":"descripcion","item-value":"id","label":"Tipo de Teléfono","readonly":"","error-messages":item.errorTipoTelf},model:{value:(item.id_tipotelefono),callback:function ($$v) {_vm.$set(item, "id_tipotelefono", $$v)},expression:"item.id_tipotelefono"}})]}},{key:"item.nro_telefono",fn:function(ref){
                var item = ref.item;
return [_c('v-text-field',{attrs:{"prefix":"+","error-messages":item.errorTelefono},on:{"blur":function($event){return _vm.validarFormularioTelefonoContacto()}},model:{value:(item.telefono),callback:function ($$v) {_vm.$set(item, "telefono", $$v)},expression:"item.telefono"}})]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.eliminarContacto(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"success"},on:{"click":function($event){_vm.dialogTelCont = !_vm.dialogTelCont}}},[_vm._v("Cerrar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }